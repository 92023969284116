import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../../src/Assets/Group 1171283252[1].png";
import { useSelector } from "react-redux";
const TrendingGiftCard = ({
  product,
  uniqueCategory,
  handleShow,
  productValues,
}) => {
  const navigate = useNavigate();
  const [notFoundStates, setNotFoundStates] = useState([]);

  const handleSubmit = (item) => {
    clientConfigurationData?.enableQuickBy ?  handleShowModal(item) : navigate(`/giftcard?productId=${item?.id}`, { state: { item } });
  };
  const productDiscount = useSelector(
    (state) =>
      state?.ClientProductMappingbyIdReducer.data?.[0]?.clientProductMapping
  );

  const getMaxPrice = (priceDenominations) => {
    const pricesArray = priceDenominations?.split(",");
    const pricesNumbers = pricesArray?.map(Number);
    return Math.max(...pricesNumbers);
  };
  const getMinPrice = (priceDenominations) => {
    const pricesArray = priceDenominations?.split(",");
    const pricesNumbers = pricesArray?.map(Number);
    return Math.min(...pricesNumbers);
  };

  const calculatePointsern = (item, isString) => {
    const productId = item.id;
    const maxPrice = getMaxPrice(item.priceDenominations);
    const minPrice = getMinPrice(item.priceDenominations);
    const discountEntry = productDiscount?.find(
      (entry) => entry.productId === productId
    );
    if (discountEntry) {
      if (discountEntry.minEarnPoints > 0) {
        return isString ? `${
          Math.round((discountEntry.minEarnPoints / minPrice) *
          (maxPrice - (maxPrice * discountEntry.customerDiscount) / 100))
        } Pts` : Math.round((discountEntry.minEarnPoints / minPrice) *
        (maxPrice - (maxPrice * discountEntry.customerDiscount) / 100));
      }
    }
    return null;
  };

  const calculatePoints = (item, isString) => {
    const productId = item.id;
    const minPrice = getMinPrice(item.priceDenominations);
    const discountEntry = productDiscount?.find(
      (entry) => entry.productId === productId
    );
    if (discountEntry) {
      if (discountEntry.maxEarnPoints > 0) {
        return isString ? `${
          Math.round((discountEntry.maxEarnPoints / minPrice) *
          (minPrice - (minPrice * discountEntry.customerDiscount) / 100))
        } Pts` : Math.round((discountEntry.maxEarnPoints / minPrice) *
        (minPrice - (minPrice * discountEntry.customerDiscount) / 100));
      }
    }
    return null;
  };

  const calculateDiscount = (item) => {
    const productId = item.id;
    const minPrice = getMinPrice(item.priceDenominations);
    const discountEntry = productDiscount?.find(
      (entry) => entry.productId === productId
    );
    if (discountEntry) {
     return  Math.round((minPrice - (minPrice * discountEntry.customerDiscount) / 100))
    }
    return null;
  };
  const handleShowModal = (item) => {
    handleShow(false);
    productValues(item);
  };

  const handleImageError = (index) => {
    setNotFoundStates((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };
  const [nav, setNav] = useState();
  const clientConfigurationData = useSelector(
    (state) => state.clientConfigurationReducer.clientConfigurationData?.[0]
  );
  
  useEffect(() => {
    setNav(clientConfigurationData?.enableQuickBy);
  }, [clientConfigurationData]);

  return product?.map((item, index) => (
    <div key={index} className="col-lg-3 col-6 mb-3 pad-0 card_mob_p custom-col">
      <div className="giftcardbox">
        <div onClick={() => handleSubmit(item)}>
          {notFoundStates[index] && (
            <div className="info-icon">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              <div>Error in image path</div>
            </div>
          )}
          {item?.customerDiscount !== 0 && (
            <p className="discountOff  ">{item?.customerDiscount}% OFF</p>
          )}
          <div className="mt-2 p-1">
            <img
              src={notFoundStates[index] ? defaultImage : item?.small}
              onError={() => handleImageError(index)}
              alt={item?.name}
              className="w-100 trendCardImg trend-rad"
            />
          </div>
          <h4 className="card-name" title={item?.name}>{item?.name?.length > 18 ? item?.name?.substring(0,15) + "...": item?.name}</h4>
        </div>
        <div className="fixFromBottom">
        {calculatePointsern(item, false) ?
          <span className={`aapoForMob trend-mb ${!calculatePoints(item, false)  ? 'm-b-2-5' : "" }`}>
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#earnpoints"
              className="start-point1"
              onClick={(e) => {
                e.preventDefault(); // Prevents navigation to "#"
                nav ? handleShowModal(item) : handleSubmit(item);
              }}
            >
              Buy & Earn up to {calculatePointsern(item, true)}
            </Link>
          </span>:<></>
        }
        {!calculatePointsern(item, false) && !calculatePoints(item, false) && 
          <span className={`aapoForMob trend-mb ${(!calculatePointsern(item, false) && !calculatePoints(item, false)) ? 'm-b-2-5' : ""}`}>
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#earnpoints"
              className="start-point1"
              onClick={(e) => {
                e.preventDefault(); // Prevents navigation to "#"
                nav ? handleShowModal(item) : handleSubmit(item);
              }}
            >
              Starting from ₹ {calculateDiscount(item)}
            </Link>
          </span>
        }
          {calculatePoints(item, false) ? <span className={`earn_upto_points trend-mb ${!calculatePointsern(item, false)  ? 'm-b-2' : "" }`}>or redeem from <span className="pts-col">{calculatePoints(item, true)}</span></span>:<></>}
          <button className="custom-button" onClick={(e)=>handleSubmit(item)}>Select</button>
        </div>
      </div>
    </div>
  ));
};

export default TrendingGiftCard;
