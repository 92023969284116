import React from "react";
import { Modal, Nav, Tab } from "react-bootstrap";
import step1 from "../../Assets/step1.png";
import step2 from "../../Assets/step2.png";
import step3 from "../../Assets/step3.png";
import modelImg from "../../Assets/remove.png";

const ModalComponent = ({ show, handleClose, productDetails, activeTab, modalClass }) => {
  const renderHtmlContent = () => {
    return { __html: productDetails?.productReducer?.tncContent };
  };

  return (
    <Modal
  show={show}
  onHide={handleClose}
  size="lg"
  aria-labelledby="exampleModalLabel"
  centered
  dialogClassName={`modal-dialog  ${modalClass}`}
>
  <div className={`modal-header justify-content-center p-1 ${modalClass}`}>
    <button
      type="button"
      className="modal-btn"
      aria-label="Close"
      onClick={handleClose}
    >
      <img className="w-35px" src={modelImg} alt="Close" />
    </button>
  </div>
  <Modal.Body className="modal-body pt-0 mt-4 p-4">
    <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>
      <Nav variant="tabs" className="justify-content-around mb-3">
        <Nav.Item>
          <Nav.Link eventKey="terms">
            <i className="fa-solid fa-book-open-reader"></i> &nbsp; Instructions
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="redeem">
            <i className=""></i> &nbsp;Terms & Conditions
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        <Tab.Pane eventKey="terms">
          <div dangerouslySetInnerHTML={renderHtmlContent()} />
        </Tab.Pane>
        <Tab.Pane eventKey="redeem">
          <div dangerouslySetInnerHTML={renderHtmlContent()} />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  </Modal.Body>
</Modal>

  );
};

export default ModalComponent;
