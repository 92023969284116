import axiosIntegration from '../Common/Axios/axiosIntegration';
import axiosInstanceClient from '../Common/Axios/axiosInstanceClient';
import API from '../Common/Endpoint/serviceConstrants';

export const callSendMailPostApi = async (payload) => {
    const { data = [] } = await axiosIntegration.post(API.mailApi, payload);
    return data;
};

export const callGetMailApi = async () => {
    const { data = [] } = await axiosInstanceClient.get(API.getMailApi);
    return data;
};

