import { call, put, takeLatest } from "redux-saga/effects";
import { callAddSpecialListGetApi } from "../Context/addSpecialListApi";
import { callCredApi } from "../Context/onSaveCredApi";
import { onSaveCreds, onSaveCredsError, onSaveCredsGet } from "../Store/Slices/cardDataSlice";

function* onGetCredCleint({ payload }) {
  try {
    const credResponse = yield call(callCredApi,payload);
    if (credResponse.httpStatusCode === "200") {
      yield put(
        onSaveCreds({
          data: credResponse.response,
          message: credResponse.errorMessage,
          status_Code: credResponse.httpStatusCode
        })
      );
    } else {
      yield put(
        onSaveCredsError({
          data: credResponse.response,
          message: credResponse.errorMessage,
          status_Code: credResponse.httpStatusCode
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onSaveCredsError({ data: {}, message, status_code: 400 }));
  }
}
export default function* onSaveCredSaga() {
  yield takeLatest(onSaveCredsGet.type, onGetCredCleint);
}
