import API from "../Common/Endpoint/serviceConstrants";
import axiosInstance from "../Common/Axios/axiosInstance";
export const loginAuthApi = async (payload) => {
    const { data = {} } = await axiosInstance.post(API.loginAuth, payload, {
      headers: {
        ...axiosInstance.defaults.headers,
        Userid: process.env.REACT_APP_USER_Name,
        Password: process.env.REACT_APP_USER_PASSWORD,
      },
    });
  return data;
};
