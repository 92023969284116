import { call, put, takeLatest } from "redux-saga/effects";
import { callGetMailApi, callSendMailPostApi } from "../Context/sendMailApi";
import { onGetMail, onGetMailError, onGetMailSuccess, onPostSendMail, onPostSendMailError, onPostSendMailSuccess } from "../Store/Slices/sendMailSlice";

function* PostSendMail({ payload }) {
  try {
    const postSendMailResponse = yield call(callSendMailPostApi, payload);
    if (postSendMailResponse.responseCode === "200") {
      yield put(
        onPostSendMailSuccess({
          postData: postSendMailResponse.response,
          message: postSendMailResponse.responseMessage,
          status_code: postSendMailResponse.responseCode,
        })
      );
    } else {
      yield put(
        onPostSendMailError({
          data: postSendMailResponse.response,
          message: postSendMailResponse.responseMessage,
          status_code:postSendMailResponse.responseCode
        })
      );
    }
  } catch (error) {
    const message = error.response.data.responseMessage  || "Something went wrong";
    yield put(onPostSendMailError({ data: [], message, status_code: 400 }));
  }
}
function* GetMail({ payload }) {
  try {
    const getMailResponse = yield call(callGetMailApi);
    if (getMailResponse.httpStatusCode === "200") {
      yield put(
        onGetMailSuccess({
          getData: getMailResponse.response,
          message: getMailResponse.errorMessage,
          status_code: getMailResponse.httpStatusCode,
        })
      );
    } else {
      yield put(
        onGetMailError({
          getData: getMailResponse.response,
          message: getMailResponse.errorMessage,
          status_code: getMailResponse.httpStatusCode,
        })
      );
    }
  } catch (error) {
    const message = error.response.data.responseMessage  || "Something went wrong";
    yield put(onGetMailError({ data: [], message, status_code: 400 }));
  }
}
export default function* sendMailSaga() {
  yield takeLatest(onPostSendMail.type, PostSendMail);
  yield takeLatest(onGetMail.type, GetMail);
}
