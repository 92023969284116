import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import bgImage from "../../../src/Assets/Mask group.png";
import boxLine from "../../Assets/NewList/box-line.png";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

const EarnPointsModal = ({ show, handleClose, modalDataValue,modalClass }) => {
  const isMobile = window.innerWidth < 992;
  const navigate = useNavigate();
  const [moduleData, setModuleData] = useState([]);
  const isLogin = sessionStorage.getItem("login");
  const V3Data = useSelector((state) => state.cardDataSliceReducer);

  const priceDenominations = modalDataValue?.priceDenominations
    ?.split(",")
    ?.map(Number);
  const [quantity, setQuantity] = useState(
    priceDenominations?.map((_, index) => (index === 0 ? 0 : 0))
  );
  const [finalAmount, setFinalAmount] = useState(0);
  const totalGiftcard = quantity?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  const discount = modalDataValue?.customerDiscount;

  useEffect(() => {
    const newFinalAmount = priceDenominations?.reduce((acc, price, index) => {
      return acc + price * quantity[index];
    }, 0);
    setFinalAmount(newFinalAmount);
  }, [quantity, priceDenominations]);

  const handleIncrease = (index) => {
    setQuantity((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      newQuantities[index] += 1;
      return newQuantities;
    });
  };

  const handleDecrease = (index) => {
    setQuantity((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      newQuantities[index] = Math.max(newQuantities[index] - 1, 0);
      return newQuantities;
    });
  };

  useEffect(() => {
    const newArrayData = priceDenominations
      ?.map((item, index) => ({
        denomination: item,
        you_pay: item - (item * discount) / 100,
        saving: (item * discount) / 100,
        quantity: quantity[index],
      }))
      .filter((obj) => obj.quantity > 0);

    setModuleData(newArrayData);
  }, [quantity, discount]);

  function calculateTotalQuantity(items) {
    return items.reduce((total, item) => total + item.quantity, 0);
  }

  const handleClick = () => {
    const quantity = calculateTotalQuantity(moduleData);
    if(quantity<= 10){
    if (finalAmount > 0) {
      if(isLogin){
        navigate(`/card?productId=${modalDataValue?.id}`, {
          state: { moduleData },
        })
      }else{
        const pathName = window.location.pathname;
        const search = window.location.search;
        localStorage.setItem("ProductListPage", pathName + search);
        window.location.href = V3Data?.SignInUrl;
      }
    } else {
      toast.error("Only 10 Products can be allowed");
    }
  }};
  const productDiscount = useSelector(
    (state) =>
      state?.ClientProductMappingbyIdReducer.data?.[0]?.clientProductMapping
  );
  const minEarnPoints = productDiscount?.find(
    (entry) => entry.productId === modalDataValue?.id
  );
  const minEarn = minEarnPoints?.minEarnPoints;
  const getMinPrice = (priceDenominations) => {
    const pricesArray = String(priceDenominations).split(",");
    const pricesNumbers = pricesArray.map(Number);
    return Math.min(...pricesNumbers);
  };

  return (
    <div className="modal_Quick">
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="exampleModalLabel"
        centered
        className={modalClass}
      >
        <ToastContainer />
        <Modal.Body>
          <div
            id="amazon_gift_card"
            className="body position-relative-QuickModal modal-content-scrollable1"
          >
            {/* Fixed Header */}
            <div className="d-flex justify-content-between align-items-center fixed-top bg-white card_h_padding">
              <div>
                <h5 className="heading mb-0 mb-5-px">{modalDataValue.name}</h5>
                <span className="pro-discount1 pro-discount-modal">
                  {modalDataValue?.customerDiscount !== 0 &&
                    `Discount Flat ${modalDataValue?.customerDiscount}%`}
                </span>
                <span className="Redeemable_online Redeemable_online_modal">
                  <span>
                    <i className="fa-solid fa-check check_icon"></i>
                  </span>
                  Redeemable Online
                </span>
                <div className="mod-head">
                  <h5 className="heading mb-0">{modalDataValue.name}</h5>
                  <span
                    className="mx-0 px-0 modal-cross"
                    role="button"
                    onClick={handleClose}
                  >
                    <i className="fa-solid fa-xmark xmark_FS"></i>
                  </span>
                </div>
              </div>
            </div>
            {/* Scrollable Content */}
            <div className="modal-content-scrollable mb-1 pt-0">
              {priceDenominations?.map((item, index) => (
                <div
                  className="d-flex justify-content-between align-item-center mb-3 position_relatives"
                  key={index}
                >
                  <div className="d-flex modal_discount_price">
                    <div
                      className="background_image"
                      style={{ backgroundImage: `url(${bgImage})` }}
                    >
                      <span className="price_in_box">
                        <span>₹</span>
                        {(item - (item * discount) / 100) % 1 === 0
                          ? Math.round(item - (item * discount) / 100)
                          : Math.round(item - (item * discount) / 100)}
                      </span>
                      <img src={boxLine} />
                    </div>
                    <div className="font-blk mr-10 col-lg-3 col-3">
                      <p>
                        {modalDataValue?.customerDiscount !== 0 && (
                          <strike
                            className="pro-mrp"
                            style={{ textDecoration: "line-through" }}
                          >
                            ₹{item}
                          </strike>
                        )}
                        &nbsp;&nbsp;
                        <span className="actual-mrp">
                          ₹
                          {(item - (item * discount) / 100) % 1 === 0
                            ? Math.round(item - (item * discount) / 100)
                            : Math.round(item - (item * discount) / 100)}
                        </span>
                      </p>
                      {Math.round(
                        (minEarn / getMinPrice(priceDenominations)) *
                          (item - (item * discount) / 100)
                      ) !== 0 && (
                        <div className="fa_star_border">
                          <span className="fa_star_box">
                            Earn&nbsp;
                            {Math.round(
                              (minEarn / getMinPrice(priceDenominations)) *
                                (item - (item * discount) / 100)
                            )}{" "}
                            Pts
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="number modal-inc_dec_btn d-flex align-item-center col-2">
                    {quantity[index] > 0 && (
                      <>
                        <button
                          className="minus"
                          style={{ color: "#00477A" }}
                          onClick={() => handleDecrease(index)}
                        >
                          −
                        </button>
                        <input
                          className="item-qnty mod-qnty"
                          type="text"
                          value={quantity[index]}
                          readOnly
                        />
                      </>
                    )}
                    <button
                      className={`plus ${
                        quantity[index] === 10 ? "quantity-disabled" : ""
                      }`}
                      style={{ color: "#00477A" }}
                      onClick={() => handleIncrease(index)}
                      disabled={quantity[index] === 10}
                    >
                      +
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <div class="info-box">Redeem your Points at checkout</div>
              <div>
                <Button
                  className="col-12 continue-btn clr-white mt-3 btn-sz"
                  onClick={handleClick}
                >
                  Continue
                </Button>
                <Button onClick={handleClose} className="cancel-link mb-2">
                  Cancel
                </Button>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EarnPointsModal;
