import axios from "axios";
import { decryptAES, encryptAES } from "./encrypt";

let url = window.location.href.includes("localhost")>0 ? process.env.REACT_APP_CLIENT_API_URL : process.env.REACT_APP_API_Gateway;
const api = axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
  },
});

if(!window.location.href.includes("localhost")>0){
// Request interceptor for encryption
api.interceptors.request.use(
  (config) => {
    // Check if the Content-Type is multipart/form-data
    const isMultipart = config.headers["Content-Type"] && config.headers["Content-Type"].includes("multipart/form-data");

    if (!isMultipart && config.data) {
      console.log(config.data);
      config.data = encryptAES(config.data); // Encrypt request body for non-multipart data
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for decryption
api.interceptors.response.use(
  (response) => {
    if (response.data) {
      response.data = decryptAES(response.data); // Decrypt response body
      console.log(response.data);
    }
    return response;
  },
  (error) => {
    // Attempt to decrypt the error response body if it exists
    if (error.response && error.response.data) {
      try {
        error.response.data = decryptAES(error.response.data); // Decrypt error response data
        console.log("Decrypted error response:", error.response.data);
      } catch (decryptError) {
        console.error("Error decrypting error response:", decryptError);
      }
    }
    return Promise.reject(error);
  }
);
}
export default api;
