import { createSlice } from '@reduxjs/toolkit';

export const clientDataSlice = createSlice({
  name: 'clientData',
  initialState: {
    clientCode: '',
    logoUrl:"",
    themeId:"",
    userType:""
  },
  reducers: {
    clientData: (state, {payload}) => {
      return{
      ...state,
      clientCode : payload.clientCode,
      logoUrl:payload.logoUrl,
      themeId:payload.themeId,
      userType:payload.userType,
      favIconImage:payload?.favIconImage,
      title:payload?.title,
      otpConfig:payload?.otpConfig
      }
    },
    resetClientData: (state, {payload}) => {
      return{
      ...state,
      clientCode : "",
      logoUrl:"",
      themeId:"",
      userType:"",
      favIconImage:"",
      title:"",
      otpConfig:""
      }
    },
  },
});

export const { clientData, resetClientData } = clientDataSlice.actions;

export default clientDataSlice.reducer;
