import React, { useState, useEffect } from "react";
import FooterLogo from "../../Assets/footer-logo.svg";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const clientConfig = useSelector(
    (state) => state.clientConfigurationReducer?.clientConfigurationData?.[0]
  );
  const location = useLocation();

  if (
    location.pathname.endsWith("/otp") ||
    location.pathname.endsWith("/myprofile")
  ) {
    return null;
  }
  const login = JSON.parse(sessionStorage.getItem("login"));

  return (
    <>
      <footer className="footer">
        <div className="top-footer new-ft pd-ft pad-ft">
          <div className="container-fluid">
            <div className="row justify-content-between">
              <div className="col-6 col-md-6 col-lg-3 col-xl-3 footer-head">
                <div className="footlist">
                  <div className="footsocial">
                    <img className="kok" src={FooterLogo} alt="img" />
                  </div>
                </div>
              </div>
              <div className="list">
                <div
                  className={`${
                    isMobile ? "" : "col-12 col-md-6 col-lg-3 col-xl-3"
                  }`}
                >
                  <div className="footlist">
                    <ul className="footul">
                      <li>
                        <Link
                          to="tel:+91 9999999999"
                          className="fnt-13 footerheadText"
                        >
                          <i
                            className="las la-phone-alt"
                            style={{ transform: "rotate(-85deg)" }}
                            // style="transform: rotate(-85deg);"
                          ></i>
                          &nbsp;{`+91 ${clientConfig?.phoneNumber}`}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="mailto:support@cc.giftstacc.com"
                          className="fnt-13 footerheadText"
                        >
                          <i className="las la-envelope"></i>
                          &nbsp;{clientConfig?.email}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {isMobile ? (
                  <>
                    {" "}
                    <div
                      className={`${
                        isMobile ? "" : "col-12 col-md-6 col-lg-3 col-xl-3"
                      }`}
                    >
                      <div className="footlist">
                        <ul className="footul">
                          <li>
                            <Link to="">About Giftstacc </Link>
                          </li>
                          <li>
                            <Link to="/static/Terms and Conditions">Terms and Conditions</Link>
                          </li>
                          <li>
                            <Link to="/static/Privacy Policy">
                              Privacy Policy
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className={`${
                        isMobile ? "" : "col-12 col-md-6 col-lg-3 col-xl-3"
                      }`}
                    >
                      <div className="footlist">
                        <ul className="footul">
                          <li>
                            <Link to="/static/FAQ">FAQ</Link>
                          </li>
                          <li>
                            <Link to="/contact-us">Contact us</Link>
                          </li>
                          <li>
                            <Link to="/static/Cancellation & Returns Policy">
                              Cancellation & Return
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div
                      className={`${
                        isMobile ? "" : "col-12 col-md-6 col-lg-3 col-xl-3"
                      }`}
                    >
                      <div className="footlist">
                        <ul className="footul">
                          <li>
                            <Link to="/contact-us">Contact Us </Link>
                          </li>
                          <li>
                            <Link to="">About Us</Link>
                          </li>
                          <li>
                            <Link to="/static/Cancellation & Returns Policy">
                              Refund Policy
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className={`${
                        isMobile ? "" : "col-12 col-md-6 col-lg-3 col-xl-3"
                      }`}
                    >
                      <div className="footlist">
                        <ul className="footul">
                          <li>
                            <Link to="/static/Terms and Conditions">
                              Terms and Conditions
                            </Link>
                          </li>
                          <li>
                            <Link to="/static/Privacy Policy">
                              Privacy Policy
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
              </div>
              
                {/* {!isMobile ? (
                  <div
                    className={`${
                      isMobile ? "" : "col-12 col-md-6 col-lg-3 col-xl-3"
                    }`}
                  >
                    <div className="footlist">
                      <h4>{login ? "My Account" : ""}</h4>
                      <ul className="footul">
                        {login ? (
                          <>
                            <li>
                              <Link to="/orders">My Orders</Link>
                            </li>
                            <li>
                              <Link to="/myprofile">My Profile</Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
            </div>
          </div>
        </div>
        <div className="footer-container-2 py-3 pd-f">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-md-12 col-12">
              <div className="copytextbox text-center">
                <span className="footer-span">
                  © 2024 Giftstacc All rights reserved. Powered by Customer
                  Capital
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
