/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./TrendingGiftCards.scss";
import { useDispatch, useSelector } from "react-redux";
import { onGetAddSpecial } from "../../Store/Slices/addSpecialListSlice";
import { onGetAllocateBrand } from "../../Store/Slices/allocateBrandSlice";
import RightArrorImg from "../../Assets/new.png";
import { Link } from "react-router-dom";
import { setFilteredAddSpecial, setFilteredAddSpecialActive } from "../../Store/Slices/trendingGiftCradsSlice";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../Assets/Group 1171283252[1].png";

const TrendingGiftCards = () => {
  const navigate = useNavigate();
  const [finalData, setFinalData] = useState();
  const dispatch = useDispatch();
  const loginAuthData = useSelector((state) => state.loginAuthReducer);
  useEffect(() => {
    if (loginAuthData?.data?.length) {
      dispatch(onGetAddSpecial());
      dispatch(onGetAllocateBrand());
    }
  }, []);

  const addSpecialData = useSelector(
    (state) => state.addSpecialReducer.getData
  );
  const allocateBrandData = useSelector(
    (state) => state?.allocateBrandReducer?.getAllocateBrandData
  );
  const productByClientData = useSelector(
    (state) => state.productReducer.productById?.[0]?.products
  );
  const productDiscount = useSelector(
    (state) =>
      state?.ClientProductMappingbyIdReducer.data?.[0]?.clientProductMapping
  );

  useEffect(() => {
    if (
      addSpecialData &&
      allocateBrandData &&
      productByClientData &&
      productDiscount
    ) {
      const filteredAddSpecialData =
        Array.isArray(addSpecialData) &&
        addSpecialData
          .filter((data) => data.enabled === true && data.isSpecial === false)
          .map((addSpecial) => {
            const allocatedBrands = allocateBrandData.filter(
              (brand) => brand.addSpecialId === addSpecial.id && brand.enabled
            );
            const products = allocatedBrands.map((brand) => {
              const product = productByClientData?.find(
                (product) => product.id === brand.productId
              );
              if (product) {
                const discount = productDiscount.find(
                  (discount) => discount.productId === product.id
                );
                const customerDiscount = discount && discount?.customerDiscount;
                return {
                  ...product,
                  customerDiscount,
                };
              }
              return product;
            });
            const filteredArray = products.filter(
              (element) => element !== undefined
            );
            return {
              sectionName: addSpecial.sectionName,
              sectionDesc: addSpecial.description,
              buttonText: addSpecial.buttonText,
              displayOrder: addSpecial.displayOrder,
              products: filteredArray,
            };
          });

      Array.isArray(filteredAddSpecialData) &&
        filteredAddSpecialData?.sort((a, b) => a.displayOrder - b.displayOrder);
      setFinalData(filteredAddSpecialData);
      dispatch(setFilteredAddSpecial(filteredAddSpecialData));
    }
  }, [
    addSpecialData,
    allocateBrandData,
    productByClientData,
    productDiscount,
    dispatch,
  ]);

  const handleClick = (item) => {
    navigate(`/giftcard?productId=${item?.id}`);
  };

  const [notFoundStates, setNotFoundStates] = useState([]);

  const handleImageError = (index, productIndex) => {
    setNotFoundStates((prevState) => ({
      ...prevState,
      [`${index}-${productIndex}`]: true,
    }));
  };

  const getMaxPrice = (priceDenominations) => {
    const pricesArray = priceDenominations?.split(",");
    const pricesNumbers = pricesArray?.map(Number);
    return Math.max(...pricesNumbers);
  };
  const getMinPrice = (priceDenominations) => {
    const pricesArray = priceDenominations?.split(",");
    const pricesNumbers = pricesArray?.map(Number);
    return Math.min(...pricesNumbers);
  };

  const calculatePoints = (item) => {
    const productId = item.id;
    const maxPrice = getMaxPrice(item.priceDenominations);
    const minPrice = getMinPrice(item.priceDenominations);
    const discountEntry = productDiscount?.find(
      (entry) => entry.productId === productId
    );
    if (discountEntry) {
      if (discountEntry.minEarnPoints > 0) {
        return `${
          parseInt((discountEntry.minEarnPoints / minPrice) *
          (maxPrice - (maxPrice * discountEntry.customerDiscount) / 100))
        } Points`;
      }
    }
    return null;
  };

  const checkcalculatePoints = (item) => {
    const productId = item.id;
    const discountEntry = productDiscount?.find(
      (entry) => entry.productId === productId
    );
    if (discountEntry) {
      if (discountEntry.minEarnPoints > 0) {
        return true;
      }
    }
    return null;
  };

  return (
    <div className="mainDiv">
      {Array.isArray(finalData) &&
        finalData.map((item, index) => (
          <div className="container mb-5" key={index}>
            <h5 className="sec-below-head">{item?.sectionName}</h5>
            <div className="row mt-4">
              {item.products !== undefined &&
                item?.products
                  .slice(0, 11)
                  ?.sort((a, b) => a.displayOrder - b.displayOrder)
                  ?.map((items, productIndex) => (
                    <div
                      className="col-md-2 col-sm-3 col-4 mb-3 mt-3 trending_card_box"
                      key={productIndex}
                    >
                      {items?.customerDiscount !== 0 && (
                        <p className="discountOff">
                          {items?.customerDiscount}% OFF
                        </p>
                      )}
                      {notFoundStates[`${index}-${productIndex}`] && (
                        <div className="info-icon">
                          <i
                            className="fa fa-info-circle"
                            aria-hidden="true"
                          ></i>
                          <div>Error in image path</div>
                        </div>
                      )}
                      <img
                        className="w-100 i-rd"
                        src={
                          notFoundStates[`${index}-${productIndex}`]
                            ? defaultImage
                            : items?.small
                        }
                        onError={() => handleImageError(index, productIndex)}
                        alt={"img"}
                        onClick={() => handleClick(items)}
                      />
                      <div className="text-center mt-2">
                        <span
                          className="upto"
                          onClick={() => handleClick(items)}
                        >
                          {checkcalculatePoints(items) ? "Earn Upto" : ""}
                        </span>
                        <p className="poin">{calculatePoints(items)}</p>
                      </div>
                    </div>
                  ))}
              {item?.products.length > 11 && (
                <div className="col-md-2 col-sm-3 col-4 mb-3 trending_card_box mt-3">
                  <Link
                    state={{
                      isTrending: true,
                      productData: item?.products,
                      name: item?.sectionName,
                    }}
                    to={`/productlist?trending=true`}
                  >
                    <img
                      className="w-100 i-rd"
                      src={RightArrorImg}
                      alt="more"
                    />
                    <div className="text-center mt-2">
                      <span className="upto">More</span>
                      <p className="poin">e-Gift Cards</p>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default TrendingGiftCards;
