import { all } from "redux-saga/effects";
import offerMasterSaga from "./offerMasterSaga";
import banerMasterSaga from "./bannerMasterSaga";
import cmsSaga from "./cmsSaga";
import translationSaga from "./translationSaga";
import loginAuthSaga from "./loginAuthSaga";
import addSpecialListSaga from "./addSpecialListSaga";
import allocateBrandSaga from "./allocateBrandSaga";
import supplierBrandListSaga from "./supplierBrandListSaga";
import bannerMasterSaga from "./bannerMasterSaga";
import ClientProductMappingbyIdSaga from "./clientProductMappingbyIdSaga";
import memberSaga from "./memberSaga";
import createCategorySaga from "./createCategorySaga";
import generateTokenSaga from "./generateTokenSaga";
import validateLoginTokenSaga from "./validateLoginTokenSaga";
import productApiSaga from "./productSaga";
import sessionLogoutSaga from "./sessionLogoutSaga";
import promotionalSaga from "./promotionalSaga";
import staticPointSaga from "./staticSaga";
import PointSaga from "./pointsSaga";
import ClientConfigurationSaga from "./clientConfigurationSaga";
import OrderSaga from './orderSaga';
import getMemberSaga from "./getMemberSaga";
import SegmentSaga from "./segmentSaga";
import switchAccountSaga from "./switchAccountSaga";
import sendMailSaga from "./sendMailSaga";
import saveCredSaga from "./onSaveCredsSaga";

export default function* rootSaga() {
  yield all([
    offerMasterSaga(),
    staticPointSaga(),
    translationSaga(),
    loginAuthSaga(),
    addSpecialListSaga(),
    allocateBrandSaga(),
    supplierBrandListSaga(),
    bannerMasterSaga(),
    offerMasterSaga(),
    banerMasterSaga(),
    cmsSaga(),
    ClientProductMappingbyIdSaga(),
    memberSaga(),
    createCategorySaga(),
    generateTokenSaga(),
    validateLoginTokenSaga(),
    productApiSaga(),
    sessionLogoutSaga (),
    promotionalSaga(),
    PointSaga(),
    ClientConfigurationSaga(),
    OrderSaga(),
    getMemberSaga(),
    SegmentSaga(),
    switchAccountSaga(),
    sendMailSaga(),
    saveCredSaga()
  ]);
}
