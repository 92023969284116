/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import tick from "../../Assets/tick.png";
import party from "../../Assets/party.png";
import defaultImage from "../../../src/Assets/Group 1171283252[1].png";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { onPostOrder } from "../../Store/Slices/orderSlice";
import {
  onGenerateTokenSubmit,
  onGenerateTokenSubmitReset,
} from "../../Store/Slices/generateTokenSlice";
import axiosIntegration from "../../Common/Axios/axiosIntegration";
import { onValidateLoginTokenSubmit } from "../../Store/Slices/validateLoginTokenSlice";
import Loader from "../../Components/Loader/Loader";
import API from "../../Common/Endpoint/serviceConstrants";
import { onSavePoints } from "../../Store/Slices/cardDataSlice";
import { onGetMember } from "../../Store/Slices/getMemberSlice";
import { onPostSendMail } from "../../Store/Slices/sendMailSlice";
const OrderConfirmation = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const paymentID = location.state;
  const [isOptEnable, setIsOptEnable] = useState();
  const [apiCalled, setApiCalled] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const generateToken = useSelector(
    (state) => state.generateTokenSliceReducer.data
  );
  const memberDataCard = useSelector((state) => state.getMemberSliceReducer);
  const memberData = useSelector(
    (state) => state.getMemberSliceReducer?.MemberData?.memberProfile?.emailId
  );
  const memberDataProfile = useSelector(
    (state) => state.getMemberSliceReducer?.MemberData?.memberProfile
  );
  const clientConfig = useSelector(
    (state) => state.clientConfigurationReducer?.clientConfigurationData?.[0]
  );
  const validateLoginTokenData = useSelector(
    (state) => state.validateLoginTokenSliceReducer.data
  );
  const V3Data = useSelector((state) => state.cardDataSliceReducer);
 const emailData = useSelector((state) => state.sendMailReducer);
 console.log(memberDataProfile);
 
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    if(!sessionStorage.getItem("orderDetails")){
      navigate("/")
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const clientConfigurationData = useSelector(
    (state) => state.clientConfigurationReducer.clientConfigurationData?.[0]
  );

  useEffect(() => {
    setIsOptEnable(clientConfigurationData?.otpRedeem);
  }, [clientConfigurationData]);

  const getActivationCode = (inde) => {
    const storedOrderDetails = JSON.parse(
      sessionStorage.getItem("orderDetails")
    );
    var q = storedOrderDetails.orderItems[inde].quantity;
    var activationCode = "";
    for (var i = 0; i < q; i++) {
      activationCode += generateUniqueNumber();
      activationCode += ";";
    }
    return activationCode;
  };

  const  cleanHTML = (htmlString) => {
    return htmlString
        .replace(/\s+/g, ' ')
        .replace(/"/g, "'")
        .trim();
}

const dynamicChildEmail = (payloadData, templateChild) =>{
  var result = [];
  payloadData.map((item)=>{
   let activationCode = item.activationCode.slice(0,-1).split(";");
   console.log(activationCode)
   let activationUrl = item.activationUrl.split(";")
   activationCode.map((itemData,index)=>{
       result.push({
      activationCode : itemData,
      activationUrl :activationUrl[index],
      value:item.denomination,
      expiryAt:item.expiryDate
      })
        })
      })
      let dynamicChild = templateChild;
      let dynamicChildData = "";
      result.map((item, index)=>{
      dynamicChildData += dynamicChild.replace("{%gcId%}",item.activationCode)
      dynamicChildData = dynamicChildData.replace("{%voucherCode%}",item.activationUrl)
      dynamicChildData = dynamicChildData.replace("{%voucherValue%}",item.value)
      dynamicChildData = dynamicChildData.replace("{%voucherDate%}",new Date(item.expiryAt).toLocaleDateString())
      })
      return dynamicChildData;
}

const dynamicEmail = (htmlString, storedOrderDetails, payloadData, templateChild) => {
  let htmlStringData = htmlString;
  debugger
  htmlStringData = htmlStringData.replace("{%memberName%}", `${memberDataProfile?.firstName} ${memberDataProfile?.lastName}`);
  htmlStringData = htmlStringData.replace("{%orderId%}", storedOrderDetails.orderId);
  htmlStringData = htmlStringData.replace("{%productUrl%}", storedOrderDetails?.cardImage || "https://d16lf5hy7u28ft.cloudfront.net/ClientB/img/default.png");
  htmlStringData = htmlStringData.replace("{%redeemLink%}", `${window.location.origin}/tnc?productId=${storedOrderDetails?.id}`);
  let childData = dynamicChildEmail(payloadData, templateChild)
  htmlStringData = htmlStringData.replace("{%child%}", childData);
  return htmlStringData;
};


  const fetchEmailTemplate = async (data, storedOrderDetails, payloadData) => {
    let template = cleanHTML(data?.mailBody)
    let templateChild = cleanHTML(data?.mailBodyChild)
    template = dynamicEmail(template, storedOrderDetails, payloadData, templateChild)
    return template;
  };
  const replacePlaceholders = (template, replacements) => {
    return template.replace(/{{(.*?)}}/g, (_, key) => replacements[key]);
  };
  const generateFormattedActivationCode = () => {
    const randomStringSegment = () =>
      Math.random().toString(36).substring(2, 6); 
    const randomAlphanumericSegment = () =>
      Math.random().toString(36).substring(2, 8); 
    return `${randomStringSegment()}-${randomAlphanumericSegment()}-${randomStringSegment()}`;
  };
  const getActivationString = (inde) => {
    const storedOrderDetails = JSON.parse(
      sessionStorage.getItem("orderDetails")
    );
    const quantity = storedOrderDetails.orderItems[inde].quantity;
    let activationCode = "";

    for (let i = 0; i < quantity; i++) {
      activationCode += generateFormattedActivationCode();
      if (i < quantity - 1) activationCode += ";";
    }

    return activationCode;
  };

  function generateUniqueNumber() {
    const part1 = Math.floor(Math.random() * 100000);
    const part2 = Math.floor(Math.random() * 1000000);
    const part3 = Math.floor(Math.random() * 100000);

    return `${part1} ${part2} ${part3}`;
  }
  const loginToken = useSelector(
    (state) => state.validateLoginTokenSliceReducer
  );
  useEffect(() => {
    const processOrder = async () => {
      const today = new Date();
      const nextYearDate = new Date(today);
      nextYearDate.setFullYear(today.getFullYear() + 1);
      const storedOrderDetails = JSON.parse(
        sessionStorage.getItem("orderDetails")
      );
      if (!storedOrderDetails) return;
      setOrderDetails(storedOrderDetails);
      const payloadData= [];
      for (const [index, order] of storedOrderDetails?.orderItems.entries()) {
        payloadData.push({
          orderId: storedOrderDetails?.orderId,
          clientId: 98,
          memberId: validateLoginTokenData.memberIdentifier,
          supplierId: 2,
          clientBrandId: storedOrderDetails.id,
          orderAmount: storedOrderDetails.totalPayableAmount,
          paymentId: paymentID?.paymentid || "q",
          orderStatus: "confirm",
          burnPoints: storedOrderDetails?.burnPoints,
          earnPoints: parseInt(storedOrderDetails.earnPoints) || 0,
          name: storedOrderDetails.name,
          mobile: validateLoginTokenData.mobileNumber,
          email: validateLoginTokenData.emailId,
          cardName: storedOrderDetails?.cardName,
          cardNetwork: "string",
          cardType: storedOrderDetails.orderItems[index].quantity.toString(),
          denomination: order.denomination,
          itemAmount: parseFloat(order.you_pay),
          cardNo: "string",
          cardpin: "string",
          activationCode: getActivationCode(index),
          activationUrl: getActivationString(index),
          expiryDate: nextYearDate,
          noOfOrder: 1,
          commission: 56,
          sumOfCommissionAmount: 0,
        })
        dispatch(
          onPostOrder({
            orderId: storedOrderDetails?.orderId,
            clientId: 98,
            memberId: validateLoginTokenData.memberIdentifier,
            supplierId: 2,
            clientBrandId: storedOrderDetails.id,
            orderAmount: storedOrderDetails.totalPayableAmount,
            paymentId: paymentID?.paymentid || "q",
            orderStatus: "confirm",
            burnPoints: storedOrderDetails?.burnPoints,
            earnPoints: parseInt(storedOrderDetails.earnPoints) || 0,
            name: storedOrderDetails.name,
            mobile: validateLoginTokenData.mobileNumber,
            email: validateLoginTokenData.emailId,
            cardName: storedOrderDetails?.cardName,
            cardNetwork: "string",
            cardType: storedOrderDetails.orderItems[index].quantity.toString(),
            denomination: order.denomination,
            itemAmount: parseFloat(order.you_pay),
            cardNo: "string",
            cardpin: "string",
            activationCode: getActivationCode(index),
            activationUrl: getActivationString(index),
            expiryDate: nextYearDate,
            noOfOrder: 1,
            commission: 56,
            sumOfCommissionAmount: 0,
          })
        );

        dispatch(
          onGenerateTokenSubmit({
            accessKey: V3Data?.accessKey,
            secrateKey: V3Data?.secrateKey,
            partnerCode: V3Data?.partnerCode,
            ClientCode: V3Data?.ClientCode,
          })
        );
      }
      if (parseInt(storedOrderDetails.burnPoints) > 0) {
        try {
          await burnPoints(
            storedOrderDetails?.orderId,
            storedOrderDetails.burnPoints,
            setLoading,
            storedOrderDetails,
            payloadData
          );
        } catch (error) {
          throw error;
        }
      }
      sessionStorage.removeItem("orderDetails");
    };
    const data = sessionStorage.getItem("orderDetails");
    if (data) {
      processOrder();
    }
  }, []);

  const callSwitchAccountApi = async (payload) => {
    try {
      const { data = [] } = await axiosIntegration.post(
        API.switchAccountApi,
        payload
      );

      dispatch(
        onSavePoints({
          points: data?.response?.memberBalance?.pointBalance,
          identifierType: data?.response?.identifierType,
          subIdentifier: data?.response?.subIdentifier,
        })
      );
    } catch (error) {
      console.log("Error calling switch account API:", error);
      navigate("/logout");
      throw error;
    }
  };
  const sendMail = async (payload) => {
    try {
      dispatch(onPostSendMail(payload));
    } catch (error) {
      console.error("Error calling switch account API:", error);
      throw error; // Rethrow error for handling in selectCard
    }
  };
  function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const amountToRedeem = (points) => {
    let pointValue = clientConfig.points;
    let price = clientConfig.price;
    let totalPoints = points;
    let totalAmount = totalPoints * pointValue * price;
    return parseFloat(totalAmount).toFixed(2);
  };

  const burnPoints = async (orderId, points, setLoading, storedOrderDetails, payloadData) => {
    const payload = {
      culture: 1,
      partnerCode: V3Data?.partnerCodeBody,
      transactionNo: orderId,
      memberIdentifier: validateLoginTokenData.memberIdentifier,
      transactionDate: getCurrentDateTime(),
      pointsToRedeem: [
        {
          point: points,
          integrationPartnerCode: 2,
        },
      ],
      redemptionPartnerCode: V3Data?.partnerCodeBody,
    };
    setLoading(true);
    try {
      const result = await axiosIntegration.post("/RedeemPoint", payload, {
        headers: {
          ...axiosIntegration.defaults.headers,
          "access-token": generateToken?.accessToken,
          ClientCode: V3Data.ClientCode,
          partnerCode: V3Data.partnerCode,
        },
      });

      // Await the switch account API call
      dispatch(
        onGetMember({
          memberIdentifier: loginToken?.data?.memberIdentifier,
          identifierType: loginToken?.data?.identifierType,
          ClientCode: V3Data?.ClientCode,
          partnerCode: V3Data?.partnerCode,
        })
      );
      setApiCalled(true);
      const template = await fetchEmailTemplate(emailData?.getMailData?.[0], storedOrderDetails, payloadData);
      const htmlContent = replacePlaceholders(template, {});
      const mailPayload = {
        toEmailId: memberData,
        templateCode: emailData?.getMailData?.[0]?.tempCode,
        content: htmlContent,
        subject: emailData?.getMailData?.[0]?.subject,
        contentValue: {
          UserName: "",
        },
      };
      await sendMail(mailPayload);
      return result.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      memberDataCard?.get_status_code === "200" &&
      memberDataCard?.MemberData &&
      apiCalled
    ) {
      setApiCalled(false);
      dispatch(
        onSavePoints({
          points: Math.round(
            memberDataCard?.MemberData?.memberBalance?.[0]?.pointBalance
          ),
        })
      );
    }
  }, [memberDataCard]);

  useEffect(() => {
    if (generateToken?.status_code === 200) {
      axiosIntegration.defaults.headers[
        "access-token"
      ] = `${generateToken?.data?.accessToken}`;
      axiosIntegration.defaults.headers["ClientCode"] = `${V3Data.ClientCode}`;
      axiosIntegration.defaults.headers[
        "partnerCode"
      ] = `${V3Data.partnerCode}`;
      dispatch(
        onValidateLoginTokenSubmit({
          loginSessionToken: sessionStorage.getItem("loginSessionToken"),
          integrationSecretKey: V3Data?.integrationSecretKey,
          temporaryToken: sessionStorage.getItem("temporaryToken"),
          urlReferrer: "",
        })
      );

      dispatch(onGenerateTokenSubmitReset());
    }
  }, [generateToken]);

  const handleRetrieveCode = (id, deno) => {
    isOptEnable
      ? navigate("/otp", { state: { orderId: id, denomination: deno } })
      : navigate(`/order-detail`,{ state: { orderId: id, denomination: deno }});
  };

  return (
    <>
      <ScrollToTop />

      {loading ? (
        <Loader />
      ) : (
        <div className="bg-white">
          <section
            className={`py-xl-3 pt-1 pb-2 mar-4 ${
              isMobile ? "mobile-view" : "desktop-hide"
            }`}
          >
            <div className="container-fluid">
              <div className="m-xl-n3 m-md-n2 m-n1">
                <div className="p-md-2 p-1 order_conf text-center">
                  {/* <i className="fa-solid fa-arrow-left clr-black-blk"></i> &nbsp; */}
                  Order Confirmation
                </div>
              </div>
            </div>
          </section>

          <section className="m-2 mt-4">
            <div className="container p-2">
              <div className="row justify-content-center">
                <div className="col-md-5 col-12 card-conf">
                  <div className="thanku-card">
                  <img className="party" src={party} alt="Party" />
                    <div className="bg-pink">
                      <div className="d-flex justify-content-start p-2">
                        <img className="w-50px tick" src={tick} alt="Tick" />
                        <div className="thankyoubox2">
                          <p className="onep">Thank You</p>
                          <p className="secondppp">
                            E-Gift card purchased successfully!
                          </p>
                        </div>
                      </div>
                      <hr className="hz-line" />
                      <div className="d-flex justify-content-between mb-2">
                        <div className="thankyoubox3">
                          <p className="thank-ans">{orderDetails?.orderId}</p>
                          <p className="thank-qns">Order Number</p>
                        </div>
                        <div className="thankyoubox3">
                          <p className="thank-ans">
                            &nbsp;
                            {`${Math.round(orderDetails?.burnPoints)} Pts`} + ₹
                            {Math.round(orderDetails?.totalPayableAmount)}
                          </p>

                          <p className="thank-qns">Order Amount</p>
                        </div>
                        <div className="thankyoubox3">
                          <p className="thank-ans bold">
                            &nbsp;
                            {orderDetails?.burnPoints <= 0
                              ? `${orderDetails?.earnPoints} Pts`
                              : 0}
                          </p>
                          <span className="thank-qns">Bonus Points </span>
                          <p className="thank-qns">earned</p>
                        </div>
                        <div className="thankyoubox3"></div>
                      </div>
                    </div>
                    <div className="thku-page-b thanku-card">
                      <div className="d-flex justify-content-between pb-md-1">
                        <div className="thankyoubox3">
                          <Link to="/">
                            <p className="keep-thank">
                              Keep shopping to earn more!
                            </p>
                          </Link>
                        </div>
                        <div className="thankyoubox3">
                          <p className="onep">
                            <i
                              class="fa fa-angle-right "
                              aria-hidden="true"
                            ></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="loyo">
                    <p className="dis-thank">
                      The Order details have been sent to your Bank Of
                      Maharashtra registered mobile number and email id. You
                      will receive an email from {clientConfig?.email}
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-12 mt-md-0 mt-4">
                  <div className="side-newthank mb-0 mb-md-4">
                    <div className="d-flex w-100 h-100 justify-content-center">
                      <Link to="/myntra">
                        <div className="w-100 order_confirmation_img_div">
                          <img
                            className="order_confirmation_img"
                            alt="card-image"
                            src={orderDetails?.cardImage || defaultImage}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = defaultImage;
                            }}
                          />
                        </div>
                      </Link>
                    </div>

                    <div className="card card_main_box mt-3">
                      <div className="card-body amazonPay_e_Gift_Card">
                        <div className="container pt-2">
                          <h5 className="card-title">
                            {orderDetails?.cardName}
                          </h5>
                          <div className="row header-row">
                            <div className="col-4 lines3_col_4 t_head py-2 text-center">
                              <strong>Value / Qty</strong>
                            </div>
                            <div className="col-4 lines3_col_4 t_head py-2 text-center">
                              <strong>You Paid</strong>
                            </div>
                            <div className="col-4 lines3_col_4 t_head py-2 text-center">
                              <strong>Code</strong>
                            </div>
                          </div>

                          {orderDetails?.orderItems?.map((card, index) =>
                            card?.quantity > 0 ? (
                              <div className="row card-row" key={index}>
                                <div className="col-4 lines3_col_4 t_body py-2 text-center tabledata">
                                  {card?.denomination}/{card?.quantity}
                                </div>
                                <div className="col-4 lines3_col_4 t_body py-2 text-center tabledata">
                                  {parseInt(card?.total)}
                                </div>
                                <div className="col-4 lines3_col_4 t_body py-2 text-center tabledata">
                                  <span
                                    onClick={() =>
                                      handleRetrieveCode(
                                        orderDetails.orderId,
                                        orderDetails.orderItems?.[0]
                                          ?.denomination
                                      )
                                    }
                                    className="text-primary getCode"
                                  >
                                    Get Code
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default OrderConfirmation;
