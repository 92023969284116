import { combineReducers } from "redux";
import bannerMasterRedcucer from "./Slices/bannerMasterSlice";
import cmsReducer from "./Slices/cmsSlice";
import translationReducer from "./Slices/translationSlice";
import loginAuthReducer from "./Slices/loginAuthSlice";
import addSpecialReducer from "./Slices/addSpecialListSlice";
import allocateBrandReducer from "./Slices/allocateBrandSlice";
import supplierBrandListReducer from "./Slices/supplierBrandListSlice";
import bannerMasterReducer from "./Slices/bannerMasterSlice";
import ClientProductMappingbyIdReducer from "./Slices/clientProductMappingbyIdSlice";
import offerMasterReducer from "./Slices/offerMasterSlice";
import activeTabSliceReducer from "./Slices/activeTabSlice";
import memberSliceReducer from "./Slices/memberSlice";
import userRoleSliceReducer from "./Slices/createCategorySlice";
import generateTokenSliceReducer from "./Slices/generateTokenSlice";
import validateLoginTokenSliceReducer from "./Slices/validateLoginTokenSlice";
import productReducer from "./Slices/productSlice";
import sessionLogoutReducer from "./Slices/sessionLogoutSlice";
import promotionalReducer from "./Slices/promotionalSlice";
import  staticPointsReducer  from "./Slices/staticPointSlice";
import trendingGiftCradsSlice from "./Slices/trendingGiftCradsSlice";
import pointsReducer from "./Slices/pointsSlice";
import clientConfigurationReducer from "./Slices/clientConfigurationSlice";
import orderReducer from './Slices/orderSlice';
import getMemberSliceReducer from './Slices/getMemberSlice';
import cardDataSliceReducer from "./Slices/cardDataSlice";
import themeSliceReducer from "./Slices/themeSlice";
import switchAccountReducer from "./Slices/switchAccountSlice";
import sendMailReducer from  "./Slices/sendMailSlice";
import clientDataReducer from  "./Slices/clientDataSlice";
const reducers = combineReducers({
  loginAuthReducer: loginAuthReducer,
  offerMasterReducer: offerMasterReducer,
  staticPointsReducer:staticPointsReducer,
  translationReducer: translationReducer,
  addSpecialReducer: addSpecialReducer,
  allocateBrandReducer: allocateBrandReducer,
  supplierBrandListReducer: supplierBrandListReducer,
  bannerMasterReducer: bannerMasterReducer,
  bannerMasterRedcucer: bannerMasterRedcucer,
  cmsReducer: cmsReducer,
  ClientProductMappingbyIdReducer: ClientProductMappingbyIdReducer,
  activeTabSliceReducer: activeTabSliceReducer,
  memberSliceReducer: memberSliceReducer,
  userRoleSliceReducer: userRoleSliceReducer,
  generateTokenSliceReducer: generateTokenSliceReducer,
  validateLoginTokenSliceReducer: validateLoginTokenSliceReducer,
  productReducer: productReducer,
  sessionLogoutReducer: sessionLogoutReducer,
  promotionalReducer: promotionalReducer,
  trendingGiftCardsReducer: trendingGiftCradsSlice, 
  pointsReducer: pointsReducer, 
  clientConfigurationReducer:clientConfigurationReducer,
  orderReducer: orderReducer,
  getMemberSliceReducer:getMemberSliceReducer,
  cardDataSliceReducer:cardDataSliceReducer,
  themeSliceReducer:themeSliceReducer,
  switchAccountReducer:switchAccountReducer,
  sendMailReducer:sendMailReducer,
  clientDataReducer:clientDataReducer
});

export default reducers;
