
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

const trendingGiftCradsSlice = createSlice({
  name: 'filteredAddSpecial',
  initialState,
  reducers: {
    setFilteredAddSpecial: (state, action) => {
      state.data = action.payload;
    },
    setFilteredAddSpecialActive: (state, action) => {
      state.active = action.payload;
    },
  },
});

export const { setFilteredAddSpecial, setFilteredAddSpecialActive } = trendingGiftCradsSlice.actions;

export default trendingGiftCradsSlice.reducer;

