/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "./UnlockPoints.scss";
import { useDispatch, useSelector } from "react-redux";
import { onStaticPointsSubmit } from "../../Store/Slices/staticPointSlice";
import img1 from "../../Assets/2x.png";
import img2 from "../../Assets/gift.png";
import img3 from "../../Assets/rupee-star.png";
import img4 from "../../Assets/calender.png";

const UnlockPoints = () => {
  const dispatch = useDispatch();
  const [unlockPoints, setUnlockPoint] = useState();
  const scrollContainerRef = useRef(null);
  const loginAuthData = useSelector((state) => state.loginAuthReducer);
  useEffect(() => {
    if (loginAuthData?.data?.length) {
      dispatch(onStaticPointsSubmit());
    }
  }, []);

  const staticPointsData = useSelector(
    (state) => state.staticPointsReducer?.data
  );


  const updatedStaticPoints = Array.isArray(staticPointsData)
    ? staticPointsData.map((item, index) => ({
        ...item,
      }))
    : [];

  const handleClick = (index) => {
    setUnlockPoint(index);
  };

  const handleScrollRight = () => {
    scrollContainerRef.current.scrollBy({
      left: 200, // Adjust this value to change the scroll amount
      behavior: "smooth",
    });
  };

  const divideTextIntoTwoLinesWithoutBreakingWords = (text) => {
    const middleIndex = Math.floor(text.length / 2);
  
    // Find the nearest space before the midpoint
    let breakIndex = text.lastIndexOf(" ", middleIndex);
  
    // If no space is found, use the first space after the midpoint
    if (breakIndex === -1) {
      breakIndex = text.indexOf(" ", middleIndex);
    }
  
    // If still no space is found (single word case), keep the text as is
    if (breakIndex === -1) {
      return [text]; // Return the entire text in one line
    }
  
    // Divide the text into two parts
    const firstLine = text.slice(0, breakIndex).trim();
    const secondLine = text.slice(breakIndex + 1).trim();
  
    return [firstLine, secondLine];
  };
  

  return (
    <section className="py-xl-3 py-2 bg-gray earn">
      <div className="container">
        {Array.isArray(staticPointsData) && staticPointsData.length > 0 && (
          <div key={0} className="mb-3">
            <h5 className="sec-below-head">
              <div>
                <span className="title">{staticPointsData[0]?.title}</span>
              </div>

              {/* <span className="subtitle">{staticPointsData[0]?.sub_Title}</span> */}
            </h5>
          </div>
        )}

        <div className="scroll-wrapper">
          <div className="scroll-container" ref={scrollContainerRef}>
            {updatedStaticPoints.length > 0 &&
              updatedStaticPoints.map((item, index) => (
                <div key={index} className="scroll-item ">
                  <div
                    className="earn-box d-flex align-items-center unlock-head"
                    onClick={() => handleClick(index)}
                  >
                    <img
                      src={`${process.env.REACT_APP_CLIENT_URL}${item?.icon}`}
                      className="fa-2x mx-3 unlockIcon d-flex align-items-center"
                      alt={`${index + 1}`}
                    />
                   <p className="m-0 align-item-center w-100 unloacDescription">
                      {divideTextIntoTwoLinesWithoutBreakingWords(item.description).map(
                        (line, index) => (
                          <>
                          <span key={index}>
                            {line}
                          </span>
                          <br />
                          </>
                        )
                      )}
                    </p>
                  </div>
                </div>
              ))}
          </div>
          <button className="scroll-button" onClick={handleScrollRight}>
            <i class="fa-solid fa-chevron-right fa-chevron-right-color"></i>
          </button>
        </div>
      </div>
    </section>
  );
};

export default UnlockPoints;
