/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import img from "../../Assets/main-logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendOtpApi } from "../../Context/sendOtpApi";
import { validateOtpApi } from "../../Context/validateOtpApi";
import { toast, ToastContainer } from "react-toastify";
import { onGenerateTokenSubmit, onGenerateTokenSubmitReset } from "../../Store/Slices/generateTokenSlice";
import { onValidateLoginTokenSubmit } from "../../Store/Slices/validateLoginTokenSlice";
import axiosIntegration from "../../Common/Axios/axiosIntegration";
import axiosIntegrationOtp from "../../Common/Axios/axiosIntegrationOtp";
import { onGetOrder } from "../../Store/Slices/orderSlice";
import { decryptAES } from "../../Common/Axios/encrypt";
const OTPModal = ({
  showModal,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const orderId = location.state;
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [timer, setTimer] = useState(30);
  const inputs = [1, 2, 3, 4];
  const [otpData, setOtpData] = useState();
  const [validateOtpData, setValidateOtpData] = useState();
  const generateToken = useSelector((state) => state.generateTokenSliceReducer);
  const loginToken = useSelector(
    (state) => state.validateLoginTokenSliceReducer
  );
  const token = useSelector((state) => state.generateTokenSliceReducer);
  

  const V3Data = useSelector((state) => state.cardDataSliceReducer);
  const loginTokenData = loginToken.data;
  useEffect(() => {
    if (!showModal) {
      const countdown = setInterval(() => {
        setTimer((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [showModal]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const otpValue = otp.join("");

  useEffect(() => {
    if(V3Data.ClientCode){
     dispatch(onGenerateTokenSubmit({
                accessKey: V3Data?.accessKey,
                secrateKey: V3Data?.secrateKey,
                partnerCode: V3Data?.partnerCode,
                ClientCode: V3Data?.ClientCode,
            }))
          }
  }, [V3Data]);


  const sendOtp = async () => {
    const payload = {
      mobileNumber: loginTokenData?.mobileNumber,
      emailId: "",
      communicationChannel: 1,
      templateId: "m001",
      emailTemplateId: "",
      otpAttributes: [],
    };
    try {
      const response = await sendOtpApi(payload, {"access-token": token?.data?.accessToken, clientCode : V3Data.ClientCode , partnerCode : V3Data.partnerCode});
      setOtpData(response);
    } catch (error) {
      toast.error("Error in sending OTP");
      console.error("Error sending OTP:", error);
      //navigate("/logout")
    }
  };

useEffect(()=>{
  if(loginTokenData.mobileNumber){
    sendOtp()
  }
    
},[loginTokenData.mobileNumber])

  const handleValidOtp = async () => {
    const payload = {
      otpTransactionId: otpData?.otpTransactionId,
      otp: otpValue,
      mobileNo: loginTokenData?.mobileNumber,
      emailId: "gssms030",
    };
    try {
      const response = await validateOtpApi(payload, {"access-token": token?.data?.accessToken, clientCode : V3Data.ClientCode , partnerCode : V3Data.partnerCode});
      // toast.success('OTP sent successfully');
      setValidateOtpData(response);
      console.log("OTP sent successfully:", response);
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };
  const resend = () => {
    setTimer(30);
    sendOtp();
  };
  useEffect(() => {
    if (validateOtpData?.errorMessage === "Success") {
      navigate(`/order-detail`, {state: { orderId: orderId?.orderId, denomination: orderId?.denomination } });
    } else if (validateOtpData?.errorCode === -1001) {
      toast.error(validateOtpData?.errorMessage);
    }
  }, [validateOtpData]);
  const formatMobileNumber = (mobile) => {
    if (!mobile) return "";

    // Ensure it's at least 10 characters long
    const lastTwo = mobile.slice(-2); // Get the last two digits
    const firstTwo = mobile.slice(0, 2); // Get the first two digits
    return `+91-${firstTwo}******${lastTwo}`;
  };

  useEffect(() => {
    if (generateToken?.status_code === 200) {
       var encData =  window.location.search.slice(1);
       var decData = decryptAES(encData)
       let params = new URLSearchParams(decData);
       let email = params.get("email");
       let orderId = params.get("orderId");
      let denomination = params.get("denomination");
      dispatch(onGetOrder({value:orderId,type:"orderId"}));
      dispatch(onGenerateTokenSubmitReset());
    }
      
    
  }, [generateToken]);


  return (
    <div className="otp_modal">
      <ToastContainer />
      <div className="otp-order-no">
        <p>Order Number</p>
        <span>Z15254</span>
      </div>
      <div
        className={`backdrop otp-backdrop ${!showModal ? "show-backdrop" : ""}`}
      />
      <div
        className={`modal fade mt-25 ${!showModal ? "show" : ""}`}
        role="dialog"
        style={{ display: !showModal ? "block" : "none" }}
      >
        <div className="modal-dialog lipmodal otp_modal">
          <div className="modal-content otp-modal">
            <div className="modal-body text-center">
              <img className="w-120px" src={img} alt="Logo" />
              <h4 className="otpname">
                Hi {loginTokenData?.firstName + " " + loginTokenData?.lastName}
              </h4>
              {/* <h4 className="otpname">Hi Chirag Gupta</h4> */}
              <p className="codeotp">
                Enter 4 digit OTP sent to{" "}
                {formatMobileNumber(loginTokenData?.mobileNumber)}
              </p>
              <div className="verification-code">
                <div className="verification-code--inputs">
                  {inputs.map((_, index) => (
                    <input
                      key={index}
                      name={`ctl00$ContentPlaceHolder1$txtotp`}
                      type="number"
                      maxLength="1"
                      id={`ctl00_ContentPlaceHolder1_txtotp`}
                      onChange={(e) => handleChange(e.target, index)}
                      value={otp[index]}
                    />
                  ))}
                </div>
              </div>
              <p className="mt-4 mb-4" id="resendotp">
                {timer > 0 ? "Resend OTP " : ""}
                {timer > 0 ? (
                  <strong id="timer">in {timer} Sec</strong>
                ) : (
                  <strong

                    id="timer"
                    onClick={() => resend()}
                    className="pointer"
                  >
                    Resend OTP
                  </strong>
                )}
              </p>
              <div className="otpText">
                <button
                  id="btnsubmitotp"
                  className="submitotp"
                  onClick={handleValidOtp}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPModal;
