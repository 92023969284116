import { createSlice } from "@reduxjs/toolkit";

export const cardData = createSlice({
  name: "card_data",
  initialState: {
    getcardData: [],
  },
  reducers: {
    onSavePoints: (state, { payload }) => {
      return {
        ...state,
        points: Math.round(payload?.points),
        identifierType: payload?.identifierType,
        subIdentifier: payload?.subIdentifier,
      };
    },
    onSaveCredsGet: (state, { payload }) => {
      return {
        ...state,
        accessKey: "",
        integrationSecretKey: "",
        secrateKey: "",
        SignInUrl:"",
        partnerCode:"",
        ClientCode:"",
        integrationId:"",
        partnerCodeBody:""
      };
    },
    onSaveCreds: (state, { payload }) => {
      const {ClientCode, SignInUrl, accessKey, integrationSecretKey, partnerCode, secrateKey,integrationId, partnerCodeBody} = extractVariables(payload?.data)
      return {
        ...state,
        accessKey,
        integrationSecretKey,
        secrateKey,
        SignInUrl,
        partnerCode,
        ClientCode,
        integrationId,
        partnerCodeBody
      };
    },
    onSaveCredsError: (state, { payload }) => {
      return {
        ...state,
        accessKey: "",
        integrationSecretKey: "",
        secrateKey: "",
        SignInUrl:"",
        partnerCode:payload?.partnerCode,
        ClientCode:payload?.ClientCode,
        integrationId:payload?.integrationId
      };
    },
  },
});

export function extractVariables(data) {
  const result = {};
  // Process SignInUrl (objects with a non-empty url)
  const signInUrl = data?.find(item => item.url !== "");
  if (signInUrl) {
      result.SignInUrl = signInUrl.url;  // Assign the url to SignInUrl
  }

  // Process paramsKey-value pairs
  data?.forEach(item => {
      if (item.isParam && item.paramsKey && item.paramsValue) {
          result[item.paramsKey] = item.paramsValue;  // Assign paramsKey as key and paramsValue as value
      }
  });

  return result;
}

export const { onSavePoints, onSaveCreds,onSaveCredsGet, onSaveCredsError } = cardData.actions;

export default cardData.reducer;
