import React, { useEffect, useRef, useState } from "react";
import CrossBTN from "../../Assets/cross-earn.png";
import Logo2 from "../../Assets/main-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { GetTranslationData } from "../../Components/GetTranslationData/GetTranslationData ";
// import sidebarImg from "../../Assets/sidenav-img.png";
// import arrowIcon from "../../Assets/icon_124_maximize-512.webp";
import sidebarImg from "../../Assets/sidenav-img.png";
import arrowIcon from "../../Assets/icon_124_maximize-512.webp";
import order from "../../Assets/order1.svg";
import contact from "../../Assets/contact.svg";
import star from "../../Assets/Star.svg";
import profile from "../../Assets/profile1.svg";
import staticGiftLogo from "../../Assets/NewList/powered-gift.png";
import logo from "../../Assets/NewList/Bom.png";

const MobileHeader = ({
  scroll,
  matchCategory,
  getInitials,
  validateLoginTokenData,
}) => {
  const navigate = useNavigate();
  const [OpenModal, setOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const dropdownRef = useRef(null);
  const productData = useSelector(
    (state) => state.productReducer.productById?.[0]?.products
  );

  const login_label = GetTranslationData("UIAdmin", "login_label");
  const sign_Out = GetTranslationData("UIAdmin", "sign_Out");
  const my_Profile = GetTranslationData("UIAdmin", "my_Profile");
  const my_Orders = GetTranslationData("UIAdmin", "my_Orders");
  const my_account = GetTranslationData("UIAdmin", "my_account");
  const [showPromotional, setShowPromotional] = useState(true);
  const isLogin = sessionStorage.getItem("login");

  const [isOpen, setIsOpen] = useState(true);
  const [isSearchActive, setIsSearchActive] = useState(false);
  // for the future scope
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleChange = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    setIsSearching(true);

    if (searchValue.trim() === "") {
      setFilteredResults([]);

      return;
    }

    const filteredData = productData?.filter((item) =>
      item?.name?.toLowerCase().includes(searchValue?.toLowerCase())
    );
    setFilteredResults(filteredData);
  };

  const promotionalList = useSelector((state) => state.promotionalReducer);
  const [promotinalData, setPromotionalData] = useState();
  const firstName = validateLoginTokenData.firstName;
  const lastName = validateLoginTokenData.lastName;
  useEffect(() => {
    const filteredData =
      Array.isArray(promotionalList?.GetPromotional) &&
      promotionalList?.GetPromotional?.filter((item) => item.enabled === true);

    setPromotionalData(filteredData);
  }, [promotionalList?.GetPromotional]);

  const handleClick = (item) => {
    navigate(`/giftcard?productId=${item?.id}`, { state: { item } });
    setSearchTerm("");
    setIsSearchActive(false);
    setShowSearch(false);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setFilteredResults([]);
      setSearchTerm("");
      setIsSearchActive(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Effect to disable background scroll
  useEffect(() => {
    if (OpenModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    // Clean up function to remove classes
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [OpenModal]);

  const [showSearch, setShowSearch] = useState(false);

  const toggleSearchBar = () => {
    setShowSearch(!showSearch);
    setIsSearchActive(!isSearchActive);
  };
  useEffect(() => {
    if (searchTerm) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [searchTerm]);
  const currentBalance = useSelector(
    (state) => state.cardDataSliceReducer?.points
  );
  const V3Data = useSelector((state) => state.cardDataSliceReducer);
  return (
    <header className="header desktop-hide">
      {isSearchActive && <div className="screen-blur" />}
      {/* Backdrop overlay when modal is open */}
      {OpenModal && (
        <div className="backdrop" onClick={() => setOpenModal(false)}></div>
      )}

      <div className={`fixheader top-hh ${scroll && "sticky"}`}>
        {showPromotional &&
          Array?.isArray(promotinalData) &&
          promotinalData?.map((item) => (
            <div className="oaoa top-hh" key={item.id}>
              <div className="d-flex align-items-baseline">
                <p className="promo width-fit-content">{item?.titleText}</p>
                <p className="mt-2 pro_link width-fit-content">
                  <Link to={item?.link} className="exp">
                    {item?.linkText} <i className="fa-solid fa-arrow-right"></i>{" "}
                  </Link>
                </p>
              </div>
              <button
                className="close-Btn"
                onClick={() => setShowPromotional(false)}
              >
                <img src={CrossBTN} alt="img" className="px-2" />
              </button>
            </div>
          ))}

        <div className="main-header fitted">
          <div className="container">
            <div className="row align-items-lg-center">
              <div className="col-xl-3 col-md-6 col-6">
                <div
                  className="button"
                  onClick={() => setOpenModal(true)}
                ></div>
                <div className="logo">
                  <Link
                    to="/"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "7px",
                    }}
                  >
                    <img
                      src={logo}
                      alt="img"
                      style={{
                        width: "104.48px",
                      }}
                    />
                    <img
                      src={staticGiftLogo}
                      alt="img"
                      style={{ width: "54.18px", height: "31.09px" }}
                    />
                    {/* <img src={Logo2} alt="img" /> */}
                  </Link>
                </div>
              </div>
              <div
                className="col-xl-3 col-md-3 col-3"
                style={{ paddingRight: isLogin ? "0px" : "0px" }}
              >
                <div className="top-right clearfix">
                  {/* <a id="btnsearch">
                      <i className="las la-search"></i>
                    </a> */}
                  <div className="search-container ">
                    <ul>
                      <li>
                        <i
                          className={`las la-search home-tab ${!isLogin ? 'loggedInSearch' :'mt-2'}`}
                          onClick={toggleSearchBar}
                        ></i>
                      </li>
                    </ul>
                    {showSearch && (
                      <div className="search-bar-container" ref={dropdownRef}>
                        <div className="search-bar-wrapper">
                          <div className="search-bar-card">
                            <i className="las la-search search-input-icon"></i>
                            <input
                              type="text"
                              id="txtsearch"
                              placeholder="Search..."
                              className="search-bar"
                              value={searchTerm}
                              autoComplete="off"
                              onChange={handleChange}
                            />
                          </div>

                          {/* Close Icon outside the card */}
                          <div className="close_icon">
                            <i
                              className="fa fa-close close-icon"
                              onClick={toggleSearchBar}
                            ></i>
                          </div>
                        </div>
                        {/* Search Suggestions */}
                        {searchTerm && (
                          <ul className="dropdown">
                            {filteredResults.length > 0
                              ? filteredResults.map((item) => (
                                  <li
                                    onClick={() => handleClick(item)}
                                    key={item.id}
                                    className="dropdown-item d-flex align-item-center"
                                  >
                                    <i
                                      className="las la-search mx-2"
                                      style={{ fontSize: "1.5em" }}
                                    ></i>{" "}
                                    {item.name}
                                  </li>
                                ))
                              : isSearching && (
                                  <li className="dropdown-item">
                                    No records found
                                  </li>
                                )}
                          </ul>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-md-3 col-3"
                style={{ paddingLeft: isLogin && "56px" }}
              >
                <div className="top-right clearfix" style={{ width: "30%" }}>
                  {isLogin ? (
                    <ul className="d-flex align-item-center justify-content-center">
                      <li className="meiconbtn">
                        <Link id="initialmob" className="">
                          <div className="profile">
                            <div className="profile-initials">
                              {getInitials(firstName, lastName)}
                            </div>
                          </div>
                        </Link>
                        <div className="logindropbox logindrop-ch">
                          <div className="logindrop2">
                            <ul>
                              {currentBalance && (
                                <li>
                                <img
                                  className="icon-sz"
                                  src={star}
                                  alt="profie"
                                />
                                  <span className="px-1 current-poit">
                                    {currentBalance?.toLocaleString() + " "+"Pts"}
                                  </span>
                                </li>
                              )}
                              <li>
                                <img
                                  className="icon-sz"
                                  src={profile}
                                  alt="profie"
                                />
                                <Link to="/myprofile" className="link-border">
                                  {my_Profile}
                                </Link>
                              </li>
                              <li>
                                <img
                                  className="icon-sz"
                                  src={order}
                                  alt="order"
                                />
                                <Link to="/orders" className="link-border">
                                  {"Orders"}
                                </Link>
                              </li>
                              <li>
                              <img
                                  className="icon-sz"
                                  src={contact}
                                  alt="contactus"
                                />
                                <Link to="/contact-us">{"Contact us"}</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  ) : (
                    <ul>
                      <li id="ctl00_lnklogin">
                        <Link to={V3Data?.SignInUrl} className="login_btn">
                          {login_label}
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="menu-nav">
          <div className="container">
            <div className={`top-menu ${OpenModal ? "topmenuopen" : ""}`}>
              <div className="d-block d-xl-none p-3">
                {OpenModal && (
                  <div onClick={() => setOpenModal(false)}>
                    <i className="las la-times closebtn"></i>
                  </div>
                )}
                <div className="mobilemenulogo text-center">
                  <img className="img" src={Logo2} alt="img" />
                </div>
              </div>

              <div
                className="sidebarMainDiv"
                style={{ maxHeight: isOpen ? "400px" : "47px" }}
              >
                <nav id="cssmenu">
                  {/* for the future scope  */}

                  <div className="heading-container heading-container_forMob">
                    <div className="sidebarImg">
                      <div className="giftCard"> Explore Gift Cards</div>
                    </div>
                    <div className="divider"></div>
                  </div>
                  <ul
                    className={`menu-items position_Relative_top_53 ${
                      isOpen ? "open" : "closed"
                    }`}
                  >
                    {Object.keys(matchCategory)?.map((item1, index) => (
                      <li key={index}>
                        <Link
                          to={`/productlist?categoryId=${encodeURIComponent(
                            item1
                          )}`}
                          state={{ item1 }}
                          onClick={() => {
                            handleClick(item1);
                            // Close the sidebar after selecting a category
                            setOpenModal(false);
                          }}
                        >
                          {item1}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
              <div
                className="sidebarSmallDiv"
                style={{ maxHeight: isOpen ? "400px" : "47px" }}
              >
                <nav id="cssmenu">
                  {/* for the future scope  */}

                  <div className="heading-container heading-container_forMob">
                    <div className="sidebarImg">
                      <img src={sidebarImg} alt="Sidebar Icon" />
                      <div className="giftCard"> Explore Gift Cards</div>
                    </div>
                    <div className="divider"></div>
                  </div>
                  <ul className={` ${isOpen ? "open" : "closed"}`}>
                    <li>
                      <Link
                        onClick={() => {
                          setOpenModal(false);
                        }}
                        to="/myprofile"
                      >
                        My Account
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setOpenModal(false);
                        }}
                        to="/orders"
                      >
                        My Orders
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default MobileHeader;
