import React, { useEffect, useMemo } from "react";
import FeaturedCard from "./FeaturedCard ";
import img1 from "../../Assets/g8.png";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TrendingGiftCard from "../ProductList/TrendingGiftCard";
import EarnPointsModal from "../ProductList/modal";
import { useState } from "react";

const FeaturedGiftCardsSection = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlProductId = urlParams.get("productId");
  const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [modalData, setModalData] = useState();
  const parsedProductId = urlProductId && parseInt(urlProductId);
  const FeaturedItem = useSelector(
    (state) =>
      state?.ClientProductMappingbyIdReducer.data?.[0]?.clientProductMapping
  );
  const enabledFeaturedItem = FeaturedItem?.filter(
    (item) => item?.isFeatured === true
  );
  const product = useSelector(
    (state) => state.productReducer.productById?.[0]?.products
  );
  const matchedProducts = enabledFeaturedItem
    ?.map((item) => product?.find((prod) => prod.id === item.productId))
    .filter((item) => item && item.id !== parsedProductId);
  const productDiscount = useSelector(
    (state) =>
      state?.ClientProductMappingbyIdReducer.data?.[0]?.clientProductMapping
  );

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const handleModalData = (item) => {
    setModalData(item);
  };

  useEffect(() => {
    if (matchedProducts && productDiscount) {
      const ProductDataWithDiscount = matchedProducts.map((product) => {
        const discount = productDiscount.find(
          (data) => data.productId === product.id
        );
        if (discount) {
          return { ...product, customerDiscount: discount.customerDiscount };
        }
        return product;
      });
      setProducts((prevProducts) => {
        const isEqual =
          JSON.stringify(prevProducts) ===
          JSON.stringify(ProductDataWithDiscount);
        return isEqual ? prevProducts : ProductDataWithDiscount;
      });
    }
  }, [matchedProducts, productDiscount]);

  return (
    <section className="letshop pro-grey fea-card">
      <div className="container-fluid">
        {showModal && (
          <EarnPointsModal
            modalDataValue={modalData}
            show={showModal}
            handleClose={handleClose}
          />
        )}
      </div>
      <div className="container featured-giftcard-bg">
        <div className="row">
          <div className="d-flex justify-content-between">
            <div className="first w-70 mb-2">
              <h5 className="profeature">Featured Gift Cards</h5>
            </div>
          </div>
          <div className="featurecards_wrapper">
            <TrendingGiftCard
              productValues={(item) => handleModalData(item)}
              handleShow={handleShow}
              product={products}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default FeaturedGiftCardsSection;
