import React, { useEffect, useState } from 'react';
import LetShop3 from './card1';
import { useLocation } from 'react-router-dom';
import PageError500 from "../../Components/PageError/PageError";
const Card = () => {
    const location = useLocation();
     const [showError, setShowError] = useState(false);
      const isLogin = sessionStorage.getItem("login");
      const pageError = {
        StatusCode: "401",
        ErrorName: "Permission Denied",
        ErrorDesription:"You are not able to access this url",
        url: "/",
        buttonText: "Back to Home",
      };
    const { arrayData } = location.state || {};
    useEffect(()=>{
        !isLogin && setShowError(true)
    },[])
    return (
        <div>
             {showError ? <PageError500 pageError={pageError} /> : <LetShop3 arrayData={arrayData} />}
        </div>
    );
};

export default Card;