import { createSlice } from "@reduxjs/toolkit";

export const sendMailSlice = createSlice({
  name: "send-mail",
  initialState: {
    isLoading: false,
    isError: false,
    sendMailData: [],
    message: "",
  },
  reducers: {
    onPostSendMail: (state) => {
      return {
        ...state,
        isPostLoading: true,
        postsendMailData: [],
      };
    },

    onPostSendMailSuccess: (state, { payload }) => {
      const { postData = [], message = "", status_code="200" } = payload;
      return {
        ...state,
        isPostLoading: false,
        sendMailData: postData,
        postMessage:message,
        post_status_code:status_code,
      };
    },

    onPostSendMailError: (state, { payload }) => {
      const { postData = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        sendMailData: postData,
        postMessage:message,
        post_status_code:status_code,
        isPostLoading: false,
      };
    },
    onPostSendMailReset: (state) => {
      return {
        ...state,
        postsendMailData: [],
        postMessage:"",
        post_status_code:null
      };
    },
    onGetMail: (state) => {
      return {
        ...state,
        isGetLoading: true,
        getMailData: [],
      };
    },

    onGetMailSuccess: (state, { payload }) => {
      const { getData = [], message = "", status_code="200" } = payload;
      return {
        ...state,
        isGetLoading: false,
        getMailData: getData,
        getMessage:message,
        get_status_code:status_code,
      };
    },

    onGetMailError: (state, { payload }) => {
      const { getData = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        getMailData: getData,
        getMessage:message,
        get_status_code:status_code,
        isGetLoading: false,
      };
    },
    onGetMailReset: (state) => {
      return {
        ...state,
        getMailData: [],
        getMessage:"",
        get_status_code:null,
        isGetLoading: false
      };
    },
  }
});

export const {
  onPostSendMail,
  onPostSendMailSuccess,
  onPostSendMailError,
  onPostSendMailReset,
  onGetMail,
  onGetMailSuccess,
  onGetMailError,
  onGetMailReset

} = sendMailSlice.actions;

export default sendMailSlice.reducer;
