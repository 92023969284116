import API from "../Common/Endpoint/serviceConstrants";
import axiosInstanceClient from '../Common/Axios/axiosInstanceClient';

export const callOrderGetApi = async ({payload}) => {
  const { data = {} } = await axiosInstanceClient.get(API.memberOrder,{
    params:
    {
      [payload.type]:payload.value,
    }
  
    });
  return data;
};

export const callOrderPostApi = async (payload) => {
  const { data = {} } = await axiosInstanceClient.post(API.order, payload);
  return data;
};

export const callOrderUpdateApi = async (payload) => {
  const { data = {} } = await axiosInstanceClient.put(API.order, payload);
  return data;
};