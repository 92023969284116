import React, { useEffect, useState } from "react";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import BlueStar from "../../Assets/BlueStar.png";
import defaultImage from "../../../src/Assets/Group 1171283252[1].png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onGetOrder } from "../../Store/Slices/orderSlice";
import BackSections from "../Giftcards/BackSections";
import PageError500 from "../../Components/PageError/PageError";

const RetrieveCode = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orderData, setOrderData] = useState([]);
  const [isOptEnable, setIsOptEnable] = useState();
  const [showError, setShowError] = useState(false);
  const isLogin = sessionStorage.getItem("login");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const clientConfigurationData = useSelector(
    (state) => state.clientConfigurationReducer.clientConfigurationData?.[0]
  );
  const validateLoginTokenData = useSelector(
    (state) => state.validateLoginTokenSliceReducer.data
  );
  const clientProduct = useSelector(
    (state) => state.productReducer?.productById?.[0]?.products
  );

  const userOrderData = useSelector((state) => state.orderReducer);

  useEffect(() => {
    setIsOptEnable(clientConfigurationData?.otpRedeem);
  }, [clientConfigurationData]);
  useEffect(() => {
    !isLogin && setShowError(true)
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(onGetOrder(validateLoginTokenData.memberIdentifier));
  }, []);

  useEffect(() => {
    setOrderData(userOrderData.orderData);
  }, [userOrderData]);

  const handleRetrieveCode = (id, deno) => {
    isOptEnable
      ? navigate("/otp", { state: { orderId: id, denomination: deno } })
      : navigate(`/order-detail`, {state: { orderId: id, denomination: deno } });
  };

  function getThumbnailUrlById(id) {
    const item = clientProduct?.find((entry) => entry.id === id);
    return item ? item.base : null;
  }
  const date = new Date();
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  const pageError = {
    StatusCode: "401",
    ErrorName: "Permission Denied",
    ErrorDesription:"You are not able to access this url",
    url: "/",
    buttonText: "Back to Home",
  };

  return (
    <>
      <ScrollToTop />
      {showError ? <PageError500 pageError={pageError} /> : <div className="bg">
        <BackSections />
        <section className="m-2">
          <div className="container p-2">
            <div className="row justify-content-center">
              {!isMobile && (
                <div className="gift-head">
                  <h2>Orders</h2>
                </div>
              )}
              <div
                className={`orders-container ${
                  isMobile ? "mobile-cards" : "desktop-cards"
                }`}
              >
                {Array.isArray(orderData) &&
                  orderData?.map((product, index) => (
                    <div className="order-card mr-4 mt-2" key={index}>
                      <div className="card-content">
                        <span className="order-image">
                          <img
                            src={getThumbnailUrlById(product.clientBrandId)}
                            alt="Amazon Logo"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = defaultImage;
                            }}
                          />
                        </span>
                        <div className="order-details">
                          <h3 className="order-heading">{`${product.name} ₹ ${product.denomination} e-Gift-Card`}</h3>
                          <div className="mt-2">
                            <div className="order-info">
                              <span className="order-info1">
                                <span>{product.orderId}</span>
                                <p>Order Number</p>
                              </span>
                              <span className="order-info1">
                                <span>{formattedDate}</span>
                                <p>Order Date</p>
                              </span>
                            </div>
                            <div className="order-info">
                              <span className="order-info1">
                                <span>
                                  {`${product.burnPoints} Pts`}+ ₹{product.orderAmount}
                                </span>
                                <p>Order Amount</p>
                              </span>
                              <span className="order-info1">
                                <span>{product.cardType}</span>
                                <p>Quantity</p>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        className="retrieve-btn mt-3"
                        onClick={() =>
                          handleRetrieveCode(
                            product.orderId,
                            product.denomination
                          )
                        }
                      >
                        Resend Code
                      </button>
                      <div class="order-bottom trend-mb p-3 mt-3">
                        You will receive the details on your registered
                        <div className="mt-1">mobile number and email id.</div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
      </div>
      }
    </>
  );
};

export default RetrieveCode;
