import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const UserDetails = ({
  totalPayableAmount,
  productReducer,
  quantities,
  updatedData,
  burnPoints,
  earnPoints,
  cash,
  points,
}) => {
  const navigate = useNavigate();
  const clientConfig = useSelector(
    (state) => state.clientConfigurationReducer?.clientConfigurationData?.[0]
  );
  const [isChecked, setIsChecked] = useState(false);
  const [openInst, setOpenInst] = useState(false);
  const [openRedeem, setOpenRedeem] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const validateLoginTokenData = useSelector(
    (state) => state.validateLoginTokenSliceReducer.data
  );

  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked);
  // };
  const generateOrderId = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const handlePayment = () => {
        const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: Math.round(totalPayableAmount).toFixed(2)* 100,
      currency: "INR",
      name: "Bank of maharastra",
      description: "Test Transaction",
      prefill: {
        name: validateLoginTokenData.firstName,
        email: validateLoginTokenData.emailId,
        contact: validateLoginTokenData.mobileNumber,
      },
      theme: {
        color: "#F37254",
      },
      handler: function (response) {
        navigate("/order-Confirmation", {
          state: { paymentid: response.razorpay_payment_id },
        });
      },
      modal: {
        ondismiss: function () {
          console.log("Checkout form closed");
        },
      },
    };

    // Initialize Razorpay payment
    const rzp1 = new window.Razorpay(options);
    rzp1.open();

    // Handle payment failure if necessary
    rzp1.on("payment.failed", function (response) {
      console.error(response.error);
      navigate("/payment-failed");
      // alert(Payment Failed: ${response.error.description});
    });
  };
  function allZero(arr) {
    return arr.every((value) => value === 0);
  }
  const handleClick = () => {
    if (allZero(quantities)) {
      toast.error("Please add some products quantitys");
    } else {
      if (parseInt(totalPayableAmount) > 0) {
        handlePayment();
      }
      const orderDetails = {
        totalPayableAmount,
        burnPoints: burnPoints,
        orderId: generateOrderId(),
        name: productReducer?.productReducerDisocount?.name,
        id: productReducer?.productReducerDisocount?.id,
        quantity: productReducer?.arrayData?.arrayData?.[0]?.quantity,
        orderItems: updatedData,
        earnPoints: earnPoints,
        cardName: productReducer?.productReducerDisocount?.name,
        cardImage: productReducer?.productReducerDisocount?.small,
      };
      sessionStorage.setItem("orderDetails", JSON.stringify(orderDetails));
      if (totalPayableAmount < 1) {
        navigate("/order-Confirmation");
      }
    }
  };
  const handleOpenInstruction = () => {
    setOpenInst(!openInst);
  };
  const handleOpenRedeem = () => {
    setOpenRedeem(!openRedeem);
  };
  const handleOpenNote = () => {
    setOpenNote(!openNote);
  };
  return (
    <>
      <ToastContainer />
      <div className="mt-3">
        <div className="bg-white rightCart bnb bnb1 padding-0">
          <div className="d-flex justify-content-between jlkkedit p-4 pt-2 pb-2  mb-2">
            <div className="user-cart">User Details</div>
          </div>

          <div className="info p-3 pt-0 pb-1">
            <div className="d-flex justify-content-start jlkk">
              <div className="mr-4 cartnamefirst">Name:</div>
              <div className="cartnamesecond">
                {validateLoginTokenData.firstName}{" "}
                {validateLoginTokenData?.lastName}
              </div>
            </div>
            <div className="d-flex justify-content-start jlkk">
              <div className="mr-4 cartnamefirst">Mobile No:</div>
              <div className="cartnamesecond">
                {validateLoginTokenData?.mobileNumber}
              </div>
            </div>
            <div className="d-flex justify-content-start jlkk">
              <div className="mr-4 cartnamefirst">Email:</div>
              <div className="cartnamesecond">
                {validateLoginTokenData?.emailId}
              </div>
            </div>
          </div>
        </div>
        <div className="tnc_redeem_note_box mt-3">
          <div
            className="item py-3"
            onClick={handleOpenInstruction}
            style={{ cursor: "pointer" }}
          >
            <div className="text">T&C and Instructions</div>
            <div className="arrow">
              <i
                className={`fa-solid ${
                  openInst ? "fa-angle-down" : "fa-angle-right"
                }`}
              ></i>
            </div>
          </div>
          {openInst && (
            <div className="mt-4 d-flex bg justify-content-between">
              <span className="checkterm"></span>
              <ul>
                {productReducer.productReducerDisocount.tncContent
                  .replace(/<\/?[^>]+(>|$)/g, "") // Remove all HTML tags
                  ?.split(/(?<=\w\.\s)/) // Split on period followed by a space
                  .map(
                    (line, index) =>
                      line.trim() && <li key={index}>• {line.trim()}</li>
                  )}
              </ul>
            </div>
          )}
          <div
            className="item py-3"
            onClick={handleOpenRedeem}
            style={{ cursor: "pointer" }}
          >
            <div className="text">How to Redeem?</div>
            <div className="arrow" onClick={handleOpenRedeem}>
              <i
                className={`fa-solid ${
                  openRedeem ? "fa-angle-down" : "fa-angle-right"
                }`}
              ></i>
            </div>
          </div>
          {openRedeem && (
            <div className="mt-2 mx-2">
              <ul>
                {productReducer.productReducerDisocount.tncContent
                  .replace(/<\/?[^>]+(>|$)/g, "") // Remove all HTML tags
                  .split(/(?<=\w\.\s)/) // Split on period followed by a space
                  .map(
                    (line, index) =>
                      line.trim() && <li key={index}>• {line.trim()}</li>
                  )}
              </ul>
            </div>
          )}
          <div
            className="item py-3"
            onClick={handleOpenNote}
            style={{ cursor: "pointer" }}
          >
            <div className="text">Note</div>
            <div className="arrow" onClick={handleOpenNote}>
              <i
                className={`fa-solid ${
                  openNote ? "fa-angle-down" : "fa-angle-right"
                }`}
              ></i>
            </div>
          </div>
          {openNote && (
            <div className="mt-2 mx-2 d-flex">
              {/* <label className="customcheckbox">
              <input
                id="ctl00_ContentPlaceHolder1_repbrands_ctl01_chkbrand"
                type="checkbox"
                name="ctl00$ContentPlaceHolder1$repbrands$ctl01$chkbrand"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className="checkmark"></span>
            </label> */}
              {/* <span className="checkterm"> {clientConfig?.consentRequired}</span> */}
              <span className="checkterm"> Demo BOM Text</span>
            </div>
          )}
        </div>
        <>
          <div className="col-lg-12 col-12 mt-4 text-center mb-4">
          {console.log(points,cash)}
            <a className="clr-white buy-detailcart" onClick={handleClick}>
              Buy now with{" "}
              {points > 0 ? (
                <>

                  {points + " " +"Pts"}
                  {cash > 0 && <> + ₹{Math.round(cash)}</>}
                </>
              ) : (
                <>₹{Math.round(totalPayableAmount)}</>
              )}
            </a>
          </div>
        </>
      </div>
    </>
  );
};

export default UserDetails;
