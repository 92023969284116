/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  onLoginAuthReset,
  onLoginAuthSubmit,
} from "../../Store/Slices/loginAuthSlice";
import {
  onTranslationReset,
  onTranslationSubmit,
} from "../../Store/Slices/translationSlice";
import { useDispatch, useSelector } from "react-redux";
import RouteConfiq from "../../Routing/routes";
import Loader from "../../Components/Loader/Loader";
import PageError500 from "../../Components/PageError/PageError";
import { config } from "../../Common/Client/ClientConfig";
import axiosInstance from "../../Common/Axios/axiosInstance";
import axiosInstanceClient from "../../Common/Axios/axiosInstanceClient";
import API from "../../Common/Endpoint/serviceConstrants";
import { onthemePost } from "../../Store/Slices/themeSlice";
import { onSaveCreds } from "../../Store/Slices/cardDataSlice";
import { clientData, resetClientData } from "../../Store/Slices/clientDataSlice";
const Auth = () => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [pageError, setPageError] = useState({
    StatusCode: "",
    ErrorName: "",
    ErrorDesription: "",
    url: "",
    buttonText: "",
  });
  const loginAuthData = useSelector((state) => state.loginAuthReducer);
  const translationData = useSelector((state) => state.translationReducer);
  const clientinfo = useSelector((state) => state.clientDataReducer);
  const currentUrl = window.location.href;

  useEffect(() => {
    let isMounted = true;
  
    const fetchClientInfo = async () => {
      try {
        const data = await getClientInfo();
        if (isMounted) {
          if (!data) {
            setShowError(true);
            dispatch(resetClientData());
            setPageError({
              StatusCode: "401",
              ErrorName: "Permission Denied",
              ErrorDesription:
                "Your application url is not registerd to our application",
              url: "/",
              buttonText: "Back to Home",
            });
          } else {
            if (data?.response?.clientCode) {
              axiosInstance.defaults.headers["client-code"] = data.response.clientCode;
              dispatch(clientData(data.response));
            }
            setShowError(false);
          }
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching client info:", error);
          setShowError(true);
          setPageError({
            StatusCode: "401",
            ErrorName: "Permission Denied",
            ErrorDesription:
              "Your application url is not registerd to our application",
            url: "/",
            buttonText: "Back to Home",
          });
        }
      }
    };
  
    fetchClientInfo();
  
    return () => {
      isMounted = false; // Cleanup flag when component unmounts
    };
  }, []);
  

  useEffect(() => {
    if(clientinfo.clientCode){
      axiosInstance.defaults.headers["client-code"]=clientinfo.clientCode
      axiosInstanceClient.defaults.headers["client-code"]=clientinfo.clientCode
    setShowLoader(true);
      dispatch(onTranslationReset());
      dispatch(
        onLoginAuthSubmit({
          partnerCode: clientinfo.userType,
          accessKey: process.env.REACT_APP_ACCESS_KEY,
          secretKey: process.env.REACT_APP_ACCESS_KEY,
        })
      );
      axiosInstance.defaults.headers["partner-code"] = clientinfo.userType;
      axiosInstanceClient.defaults.headers["partner-code"] = clientinfo.userType;
     }
  }, [clientinfo.clientCode]);

  const getClientInfo = async () => {
    try {
      const { data = {} } = await axiosInstance.get(API.clientbyid, {
        params: {
          platformDomainUrl: window.location.origin,
        },
        headers: {
          ...axiosInstance.defaults.headers,
          Userid: process.env.REACT_APP_USER_Name,
          Password: process.env.REACT_APP_USER_PASSWORD,
        },
      });
      return data;
    } catch (ex) {
      return null;
    }
  };
  
  
    const getThemeInfo = async(themeId) =>{
      try{
      const { data = {} } = await axiosInstance.get(API.themeManagement,{
        params:
        {
          Id: themeId
        }

        });
      return data;
      }catch(ex){
        return null;
      }
      }

  useEffect(() => {
    if (loginAuthData?.status_code === 200) {
      sessionStorage.setItem("clientCode", loginAuthData?.data?.[0]?.clientId);
      axiosInstance.defaults.headers.Authorization = `Bearer ${loginAuthData?.data?.[0]?.token}`;
      axiosInstanceClient.defaults.headers.Authorization = `Bearer ${loginAuthData?.data?.[0]?.token}`;      
        getThemeInfo().then((theme) => {
        dispatch(onthemePost(theme?.response?.[0]))
        document.documentElement.style.setProperty("--explore-back-color",theme?.response?.[0]?.promotionalStripBGColor);
        document.documentElement.style.setProperty("--explore-earn-text-color",theme?.response?.[0]?.promotionalStripTextColor);
        document.documentElement.style.setProperty("--promotional-hover-text-color",theme?.response?.[0]?.promotionalStripOnHover);
        document.documentElement.style.setProperty("--promotional-hover-text-color",theme?.response?.[0]?.promotionalStripOnHover);
        document.documentElement.style.setProperty("--bg-header",theme?.response?.[0]?.headerBgColor);
        document.documentElement.style.setProperty("--desktop-header-text-color",theme?.response?.[0]?.headerTextColor);
        document.documentElement.style.setProperty("--dark-grey",theme?.response?.[0]?.footerBgColor);
        document.documentElement.style.setProperty("--footer-text-color",theme?.response?.[0]?.footerTextColor);
        document.documentElement.style.setProperty("--dark-blue-color",theme?.response?.[0]?.primaryBgColor);
        document.documentElement.style.setProperty("--keep-bg-color",theme?.response?.[0]?.thankYouCardKeepShoppingBGColor);
        document.documentElement.style.setProperty("--white-color-keep",theme?.response?.[0]?.thankYouCardKeepShoppingTextColor);
        document.documentElement.style.setProperty("--light-green-bg",theme?.response?.[0]?.thankYouCardBackgroundColor);
        document.documentElement.style.setProperty("--bg-white-2",theme?.response?.[0]?.infoBadgeVoucherBGColor);
        document.documentElement.style.setProperty("--voucher-color",theme?.response?.[0]?.infoBadgeVoucherTextColor);
        document.documentElement.style.setProperty("--earn-text-color",theme?.response?.[0]?.infoBadgePointsYouWillEarnTextColor);
        document.documentElement.style.setProperty("--light-blue-1",theme?.response?.[0]?.infoBadgePointsYouWillEarnBGColor);
    });
      dispatch(onTranslationSubmit());
      dispatch(onLoginAuthReset());
    } else if (loginAuthData?.status_code) {
      setShowError(true);
      setShowLoader(false);
      setPageError({
        StatusCode: loginAuthData?.status_code,
        ErrorName: "Internal Server Error",
        ErrorDesription: "You do not have permission to view this resource",
        url: "/",
        buttonText: "Back to Home",
      });
    }
  }, [loginAuthData]);

  useEffect(() => {
    if (translationData.status_code === 200 && !translationData?.isLoading) {
      setShowLoader(false);
      setShowError(false);
      setIsAuthenticated(true);
      dispatch(onTranslationReset());
    } else if (
      translationData?.status_code !== 200 &&
      translationData?.status_code
    ) {
      setShowError(true);
      setShowLoader(false);
      setPageError({
        StatusCode: "500",
        ErrorName: "Internal Server Error",
        ErrorDesription: "You do not have permission to view this resource",
        url: "/",
        buttonText: "Back to Home",
      });
    }
  }, [translationData]);

  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <>
          {showError ? <PageError500 pageError={pageError} /> : isAuthenticated  && <RouteConfiq />}
        </>
      )}
    </>
  );
};

export default Auth;
